/* global EM */
import React, { useState, useEffect } from 'react';
import { Button, Modal, ModalHeader, ModalBody, ModalFooter, FormGroup, Col, Form, Label, InputGroup, InputGroupAddon } from 'reactstrap';
import MonthPicker from '../MonthPicker';
import Select from 'react-select';
import Creatable from 'react-select/creatable';
import Dates from '../../util/Dates';
import UserIconAndName from "../UserIconAndName";
import ProjectionsManager from '../../entities/ProjectionsManager';
import Spinner from '../Spinner';

async function getMatchingProjections(WorkItemName, ActivityName, EmployeeId){
    let output = {};
    if (!WorkItemName || !ActivityName || !EmployeeId)return output;
    
    let PM = new ProjectionsManager();

    await EM.schedules.loadDefaultItem();
    let projectionsFile = await PM.loadDefaultProjections();

    if (!projectionsFile)return output;
    if (!projectionsFile.data.length === 0)return output;

    let activity = EM.activities.findByKey(ActivityName.trim());
    if (!activity)return output;

    let employee = EM.employees.byId(EmployeeId);
    if (!employee)return output;

    let matches = projectionsFile.findProjections(WorkItemName, activity.ActivityId, employee.RoleId);
    if (matches && matches.length > 0){
        let tmpMatch = matches[0];
        output = {
            begin: tmpMatch[projectionsFile.filterIndexes.begin],
            end: tmpMatch[projectionsFile.filterIndexes.end],
            value: tmpMatch[projectionsFile.filterIndexes.value]
        };
    }

    return output;
}

export default function AssignmentEditDialog(props){
    const [ assignment, setAssignment ] = useState(props.assignment);
    const [ employeeList, setEmployeeList ] = useState(null);
    const [ labelList, setLabelList ] = useState(null);
    const [ match, setMatch ] = useState(null);

    useEffect(() => {
        EM.loadEntities([EM.users, EM.roles, EM.employees]).then(() => {
            let employeeList = EM.employees.asOptionList((row) => {
                let lbl = row['FirstName'] + ' ' + row['LastName'];
                let role = EM.roles.byId(row.RoleId);
                if (role) lbl = lbl + ' (' + role.Name + ')';
                return lbl;
            }, true);
            setEmployeeList(employeeList);
        }); 

        let labelSetting = EM.getSetting('AdditionalLabels');  
        if (labelSetting){
            let output = [];
            labelSetting.split(',').map((label) => {
                return output.push({ value: label.trim(), label: label.trim() });
            });
            setLabelList(output);
        }   
    }, []);

    let { WorkItemName, ActivityName } = props.assignment;
    let EmployeeId = assignment.EmployeeId;
    useEffect(() => {
        getMatchingProjections(WorkItemName, ActivityName, EmployeeId).then((foundMatch) => {
            setMatch(foundMatch);
        });
    }, [ WorkItemName, ActivityName, EmployeeId ]);

    function onClose(fromApply){
        props.onClose(fromApply);
    }  
    
    function onEdit(){
        if (props.onEdit)props.onEdit(assignment);
        onClose("true");
    }

    function setProp(prop, value){
        let modAssignment = Object.assign({}, assignment, { [prop]: value });
        setAssignment(modAssignment);
    }

    function setProps(modifications){
        let modAssignment = Object.assign({}, assignment, modifications);
        setAssignment(modAssignment);
    }    

    let currentEmployee = null;
    if (employeeList){
        employeeList.sort((a,b)=>{if(a.LastName < b.LastName){return -1;} return 0;})
        currentEmployee = employeeList.find(opt => opt.value === assignment.EmployeeId);
    }

    let statues = [{ value: 'Assigned', label: 'Assigned' }, { value: 'Locked', label: 'Locked' }];
    let begin = Dates.fromISO(assignment.BeginDate);
    let end = Dates.fromISO(assignment.EndDate);
    let value = (assignment.Value * 100).toFixed(0);

    return (
        <Modal fade={false} isOpen={props.isOpen} className={'notifications-dialog ' + (EM.currentTheme === 'Dark'?'dark-mode ': ' ') + props.className} backdrop="static">
            <ModalHeader toggle={onClose}>
                {EM.t('staffing.editDialogTitle')}
            </ModalHeader>
            <ModalBody>
                <Form className="tight">                                  
                    <FormGroup row>
                        <Label sm={3}>Task:</Label>
                        <Col sm={9}>
                            <span className="form-control dummy">{assignment.WorkItemName || '<blank>'}</span>
                        </Col>
                    </FormGroup>
                    <FormGroup row>
                        <Label sm={3}>Activity:</Label>
                        <Col sm={9}>
                            <span className="form-control dummy">{assignment.ActivityName || '<blank>'}</span>
                        </Col>
                    </FormGroup>
                    <FormGroup row>
                        <Label sm={3}>Employee:</Label>
                        <Col sm={9}>
                            <Select
                                className="single-select"
                                classNamePrefix="single-select"
                                isSearchable={true}
                                isClearable={false}
                                isMulti={false}
                                value={currentEmployee}
                                options={employeeList}
                                placeholder={EM.t('util.filtering.placeholderDefault')}
                                onChange={(selectedItem) => {
                                    setProp('EmployeeId', selectedItem.value);
                                }}
                            />
                        </Col>
                    </FormGroup>            
                    <FormGroup row>
                        <Label sm={3}>Date Range:</Label>
                        <Col sm={9}>
                            <div className="month-range">
                                <MonthPicker
                                    placeholder={EM.t('staffing.tools.from')}
                                    date={begin}
                                    onChange={(date) => {
                                        setProp('BeginDate', date.toISO());                                    
                                    }}
                                />
                                <MonthPicker
                                    placeholder={EM.t('staffing.tools.to')}
                                    date={end}
                                    onChange={(date) => {
                                        setProp('EndDate', date.toISO());                                    
                                    }}
                                />                            
                            </div>
                        </Col>
                    </FormGroup>
                    {assignment.WorkItemName && assignment.ActivityName ?
                        <FormGroup row>
                            <Label sm={3}>&nbsp;</Label>
                            <Col sm={9}>
                                {match?
                                    <i className="font-sm text-muted d-flex justify-content-between">
                                        {match.begin && match.end ?
                                            <>
                                                Originally: {Dates.toMonthYearStrShort(match.begin)}&nbsp;&nbsp;to&nbsp;&nbsp;{Dates.toMonthYearStrShort(match.end)}
                                                <button type="button" className="btn btn-sm btn-hyperlink" onClick={() => {
                                                    const adjustedEndDate = match.end.set({ hour: 23, minute: 0, second: 0, millisecond: 0 });
                                                    setProps({ 
                                                        BeginDate: match.begin.toISO(), 
                                                        EndDate: adjustedEndDate.toISO() 
                                                    }); 
                                                }}>{EM.t('util.apply')}</button>
                                            </>
                                        : 
                                            <>{EM.t('staffing.noMatchingFound')}</>
                                        }
                                    </i>
                                : 
                                    <Spinner />
                                }
                            </Col>
                        </FormGroup>                            
                    : null }
                    <FormGroup row>
                        <Label sm={3}>Value:</Label>
                        <Col sm={9}>
                            <InputGroup>    
                                <input type="number" value={value} className={"form-control editor"} onChange={event => {
                                    let value = event.target.value;
                                    if (value)setProp('Value', value / 100);
                                }} onBlur={(event) => {
                                    let value = event.target.value;
                                    if (value)setProp('Value', value / 100 );
                                    event.target.blur();
                                }}/>                             
                                <InputGroupAddon addonType="append">%</InputGroupAddon> 
                            </InputGroup>
                        </Col>
                    </FormGroup> 
                    {assignment.WorkItemName && assignment.ActivityName ?
                        <FormGroup row>
                            <Label sm={3}>&nbsp;</Label>
                            <Col sm={9}>
                                {match?
                                    <i className="font-sm text-muted d-flex justify-content-between">
                                        {match.value ?
                                            <>
                                                Originally: {Math.round(match.value * 100)}%
                                                <button type="button" className="btn btn-sm btn-hyperlink" onClick={() => {
                                                    setProp('Value', match.value); 
                                                }}>{EM.t('util.apply')}</button>
                                            </>
                                        : 
                                            <>{EM.t('staffing.noMatchingFound')}</>
                                        }
                                    </i>
                                : 
                                    <Spinner />
                                }
                            </Col>
                        </FormGroup>                            
                    : null }                                         
                    <FormGroup row>
                        <Label sm={3}>Label:</Label>
                        <Col sm={9}>
                            <Creatable
                                className="single-select"
                                classNamePrefix="single-select"
                                isSearchable={true}
                                isClearable={true}
                                isMulti={false}
                                options={labelList}
                                placeholder={EM.t('util.filtering.placeholderDefault')}
                                formatCreateLabel={(input) => { return EM.t('util.select-create', null, [input]) }}
                                value={{ value: assignment.Label, label: assignment.Label }}
                                onChange={(selectedItem) => {
                                    setProp('Label', selectedItem ? selectedItem.value : null);
                                }}  
                            />
                        </Col>
                    </FormGroup> 
                    <FormGroup row>
                        <Label sm={3}>Status:</Label>
                        <Col sm={9} style={{
                                    cursor: EM.isStrictlyDomainEditor() ? 'not-allowed' : null
                                  }}>
                            <Select
                                className="single-select"
                                classNamePrefix="single-select"
                                isSearchable={true}
                                isClearable={false}
                                isMulti={false}
                                value={statues.find(item => item.value === (assignment.Status || 'Assigned'))}
                                options={statues}
                                placeholder={EM.t('util.filtering.placeholderDefault')}
                                onChange={(selectedItem) => {
                                    setProp('Status', selectedItem ? selectedItem.value : null);
                                }}
                                isDisabled={EM.isStrictlyDomainEditor()}
                            />
                        </Col>
                    </FormGroup>    
                    <FormGroup row>
                        <Label sm={3}>Created:</Label>
                        <Col sm={9}>
                            <span className="form-control dummy d-flex font-xs mt-1 justify-content-between">
                                {Dates.isoToDateTime(assignment.CreatedOn)}&nbsp;
                                {assignment.CreatedBy?<UserIconAndName user={EM.users.byId(assignment.CreatedBy)} className="ml-2 font-xs"/>:'N/A'}
                            </span>
                        </Col>
                    </FormGroup> 
                    <FormGroup row>
                        <Label sm={3}>Modified:</Label>
                        <Col sm={9}>
                            <span className="form-control dummy d-flex font-xs mt-1 justify-content-between">
                                {Dates.isoToDateTime(assignment.ModifiedOn)}&nbsp;
                                {assignment.ModifiedBy?<UserIconAndName user={EM.users.byId(assignment.ModifiedBy)} className="ml-2 font-xs" />:'N/A'}
                            </span>
                        </Col>
                    </FormGroup>                                                                                                                                                                                                    
                </Form>
            </ModalBody>
            <ModalFooter>               
                <Button color="secondary" onClick={onClose}>{EM.t('util.closeButton')}</Button>
                <Button color="primary" onClick={onEdit}>{EM.t('util.apply')}</Button>
            </ModalFooter>
        </Modal>
    );
}